import React, { useState } from 'react'
import Layout from '../../layout'
import ListingFilters from "../../ListingFilters";
import ProjectCard from '../../ProjectCard';

export default function ProjectListingAll({pageContext: {all_projects}}) {

    let {edges: projects} = all_projects.allMarkdownRemark;

    let [state, setState] = useState({
        filteredData: [],
        filterApplied: false
    });

    let unfiltered = state.filteredData.length === 0 && state.filterApplied === false

    let available_projects = unfiltered ? projects : state.filteredData;

    return (
        <Layout width_restrict_all={true} meta_title={"Projects"} meta_desc={"See what projects Jonny has been working on!"} meta_img={`/assets/metadata_images/default/projects.png`}>

            <h1 className='page-header'>Projects</h1>

            <ListingFilters all_listings={projects} setFilterData={setState} filter_subject="Technology" />

            <div className={`listing-wrapper project-card-container flex-container-column ${unfiltered ? "unfiltered" : "filtered"}`}>

                {available_projects.map((project, i) => {

                    // let {id} = project.node;
                    let image = project.node.frontmatter.image.childImageSharp.fluid;
                    let frontmatter = project.node.frontmatter;
                    let slug = project.node.fields.slug;

                    return <ProjectCard img_fluid={image} frontmatter={frontmatter} slug={slug} key={i} />

                })}

            </div>

        </Layout>
    )
}


