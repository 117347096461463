import React, { useState } from 'react'
import "../styles/components/_filters.scss"

export default function ListingFilters({all_listings, setFilterData, filter_subject}) {

    function clearFilters() {
        setCurrentFilter("");
        setFilterData({
            filteredData: [],
            filterApplied: false
        })
    }

    let filters = getFiltersAndAmounts(all_listings);

    let [current_filter, setCurrentFilter] = useState("")
    
    return (
        <div className="filters-container flex-container">
            <div className="filters-container__title flex-container">
                <span>Filter by {filter_subject}:</span>
                <button className={`filters-container__title__clear ${current_filter !== "" ? "active" : ""}`} onClick={clearFilters}>Clear</button>
            </div>
            <div className="filters flex-container">
                {Object.keys(filters).map((filter_key, i) => {

                    return <Filter 
                            key={i}
                            current_filter={current_filter} 
                            filters={filters} 
                            unfiltered_posts={all_listings}
                            filter_key={filter_key} 
                            setCurrentFilter={setCurrentFilter}
                            setFilterData={setFilterData}
                            />

                })}
            </div>
        </div>
    )
}

function Filter({current_filter, filter_key, filters, unfiltered_posts, setCurrentFilter, setFilterData}) {

    // current_filter - Parent State of the current applied filter
    // filter_key - Filter Title
    // filters - Object of Filter titles and Quantities
    // unfiltered_posts - All Posts before filtering
    // setCurrentFilter - CB function to update parent current_filter state
    // setFilterData - CB function to update parent parent state. Contains the filtered post list and info on the current applied filter

    function handleClick() {
        
        setCurrentFilter(current_filter === filter_key ? "" : filter_key);

        if(current_filter !== filter_key) {

            let filtered_posts = filterListings(unfiltered_posts, filter_key);

            setFilterData({
                filteredData: filtered_posts,
                filterApplied: true
            })

        } else {

            setFilterData({
                filteredData: [],
                filterApplied: false
            })

        }

    }

    return (
        <button type='button' aria-pressed={`${current_filter === filter_key ? true : false}`} className={`filters__filter-button ${current_filter === filter_key ? "active" : "inactive"}`} onClick={handleClick}>
                            <p>{filter_key}</p><span>{filters[filter_key]}</span>
        </button>
    )
}

function filterListings(all_posts, active_filter) {

    return all_posts.filter(post => {

        let {tags, technologies} = post.node.frontmatter;

        let formatted_arr = (tags || technologies).map(item => {
            return formatTag(item)
        })

        return formatted_arr.indexOf(formatTag(active_filter)) > -1

    })

}

function getFiltersAndAmounts(all_listings) {
    let each_posts_tags = all_listings.map(post => {

        return post.node.frontmatter.tags || post.node.frontmatter.technologies;

    })

    var occurences = {};
    
    each_posts_tags.forEach(tag_set => {
        
        tag_set.forEach(tag => {
            
            let tag_lc = formatTag(tag)

            occurences[tag_lc] = (occurences[tag_lc] || 0) + 1;

        })

    })


    return occurences;


}

function formatTag(tag) {
    return tag.toLowerCase().replace('js', 'JS').replace("css", "CSS").trim();
}